import axios from 'axios';

export type TGetGeoLocationResponse = {
  country_code?: string;
  country_name?: string;
  city?: string;
  postal?: string;
  IPv4?: string;
  state?: string;
  latitude?: number;
  longitude?: number;
};

export const getGeoLocationApi = (): Promise<TGetGeoLocationResponse> => {
  return new Promise((resolve, reject) => {
    axios
      .get<TGetGeoLocationResponse>('https://geolocation-db.com/json/')
      .then(response => resolve(response.data))
      .catch(reject);
  });
};
