import { mutatorAction } from 'satcheljs';

import { TGetGeoLocationResponse } from '../api/get-geo-location.api';
import { getGeoLocationStore } from '../store/geo-location.store';

export const setGeoLocationDataAction = mutatorAction(
  'setGeoLocationDataAction',
  (geoResponseData: TGetGeoLocationResponse | undefined) => {
    getGeoLocationStore().data = !geoResponseData
      ? undefined
      : {
          city: geoResponseData.city,
          countryCode: geoResponseData.country_code,
          countryName: geoResponseData.country_name,
          ipV4: geoResponseData.IPv4,
          latitude: geoResponseData.latitude,
          longitude: geoResponseData.longitude,
          postal: geoResponseData.postal,
          state: geoResponseData.state
        };
  }
);
