import { AxiosError } from 'axios';

export type TApiError = AxiosError<{
  code?: string;
  message?: string;
}>;

export type TSearchResult<T> = {
  _embedded?: T;
  page: {
    size: number;
    totalElements: number;
    totalPages: number;
    number: number;
  };
};
