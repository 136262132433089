import FingerprintJS from '@fingerprintjs/fingerprintjs';
import axios from 'axios';
import dayjs from 'dayjs';

import { LocalApiLog, TApiLogMethod } from '@totopkg/shared-util-log';

import { GeoLocationStore } from '../store-geo-location';
import { TApiError } from './api.type';

const API = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
});

API.interceptors.request.use(
  requestConfig => {
    const serializerConfig = requestConfig;

    // check request Header "clean-request":"no-clean"
    if (serializerConfig.headers && serializerConfig.headers['clean-request']?.toLocaleLowerCase() === 'no-clean') {
      return serializerConfig;
    }

    // clean params for GET method
    serializerConfig.paramsSerializer = params => {
      let result = '';
      Object.keys(params).forEach(key => {
        if (params[key] == null) return;

        const serializerParams = params;
        if (typeof serializerParams[key] === 'string') {
          serializerParams[key] = params[key].trim().replace(/\s+/g, ' ');
          if (!serializerParams[key]) return; // check empty
        }

        result += `${key}=${encodeURIComponent(serializerParams[key])}&`;
      });

      return result.substring(0, result.length - 1);
    };

    // clean body data for post, patch
    try {
      if (serializerConfig.headers) {
        const contentType = serializerConfig.headers['Content-Type']?.toString();

        if (contentType?.includes('application/json')) {
          const bodyJsonData = serializerConfig.data;
          if (bodyJsonData) {
            Object.keys(bodyJsonData).forEach(key => {
              if (typeof bodyJsonData[key] === 'string') {
                bodyJsonData[key] = bodyJsonData[key].trim().replace(/\s+/g, ' ');
              }
            });

            serializerConfig.data = JSON.stringify(bodyJsonData);
          }
        } else if (contentType?.includes('application/x-www-form-urlencoded')) {
          const bodyFormData: URLSearchParams = new URLSearchParams(serializerConfig.data);

          bodyFormData?.forEach((value: FormDataEntryValue, key: string) => {
            if (typeof value === 'string') {
              bodyFormData.set(key, value.toString().trim().replace(/\s+/g, ' '));
            }
          });

          serializerConfig.data = bodyFormData;
        }
      }
    } catch (error) {
      // no handle
      console.error('Axios request error:', error);
    }

    return serializerConfig;
  },
  error => {
    throw error;
  }
);

API.interceptors.response.use(
  response => response,
  (error: TApiError) => {
    // ** write error logs
    LocalApiLog.write({
      id: error.response?.headers['toto-request-id'],
      requestId: error.response?.headers['toto-request-id'],
      timeStamp: dayjs(error.response?.headers?.['date']).toISOString(),
      url: error.config?.url,
      requestHeader: { ...error.config?.headers, Authorization: null },
      responseHeader: error.response?.headers,
      method: error.config?.method?.toUpperCase() as TApiLogMethod,
      severity: 'error',
      messageText: `[${error.response?.data.code}] ${error.config?.method?.toUpperCase()} ${dayjs(
        error.response?.headers?.['date']
      ).toISOString()}: ${error.config?.url} ${error.response?.data.message}`,
      errorCode: error.response?.data.code,
      errorDescription: error.response?.data.message,
      body: error.response?.data,
      host: window.location.host,
      appVersion: localStorage.getItem('app-version') || undefined,
      deviceId: localStorage.getItem('device-id') || undefined,
      ipAddress: GeoLocationStore.getGeoLocationDataLocal()?.ipV4 || undefined,
      partnerId: localStorage.getItem('partner-id') || undefined,
      userId: localStorage.getItem('user-id') || undefined,
      country: GeoLocationStore.getGeoLocationDataLocal()?.countryCode || undefined,
      pageUrl: window.location.pathname
    });

    // ** catch error
    if (error?.response?.status && [401, 403].includes(error.response.status)) {
      throw error.response.status;
    }

    throw error?.response?.data?.code || 'default';
  }
);

const setApiAccessToken = (accessToken: string | undefined) => {
  if (accessToken) API.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  else delete API.defaults.headers.common.Authorization;
};

const setDefaultDeviceId = async () => {
  if (API.defaults.headers['Device-Id']) return;

  const localDeviceId = localStorage.getItem('device-id');
  let deviceId = localDeviceId;

  if (!localDeviceId) {
    const fingerprint = await FingerprintJS.load();
    const fingerprintId = await fingerprint.get();
    deviceId = fingerprintId.visitorId;
  }

  API.defaults.headers['Device-Id'] = deviceId;

  if (!localDeviceId && deviceId) localStorage.setItem('device-id', deviceId);
};

setDefaultDeviceId();

export { API, setApiAccessToken };
