export interface IGeoLocationData {
  countryCode?: string;
  countryName?: string;
  city?: string;
  postal?: string;
  ipV4?: string;
  state?: string;
  latitude?: number;
  longitude?: number;
}

export interface IGeoLocationStore {
  data: IGeoLocationData | undefined;
}
