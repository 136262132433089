import { orchestrator } from 'satcheljs';

import { fetchGeoLocationAction } from '../action/fetch-geo-location.action';
import { getGeoLocationApi } from '../api/get-geo-location.api';
import { setGeoLocationDataAction } from '../mutator-action/set-geo-location-data.action';
import { saveGeoLocationDataLocal } from '../util/save-geo-location-data-local.util';

orchestrator(fetchGeoLocationAction, actionMessage => {
  const { callback } = actionMessage;

  getGeoLocationApi()
    .then(response => {
      setGeoLocationDataAction(response);
      saveGeoLocationDataLocal(response);
      callback?.success?.(response);
    })
    .catch(error => callback?.error?.(error))
    .finally(callback?.finally);
});
